@charset "UTF-8";
*, *:before, *:after {
  box-sizing: border-box; }

/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

html {
  font-size: 106.25%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  line-height: 1.76471; }

body {
  margin: 0;
  padding: 0;
  font-family: Georgia, "Times New Roman", serif;
  font-weight: 400;
  color: #333; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold; }

h1, .h1 {
  margin-bottom: 0.17652em;
  font-size: 1.9994em;
  line-height: 1.05914; }
  @media (min-width: 43.75em) {
    h1, .h1 {
      margin-bottom: 0.12484em;
      font-size: 2.82715em;
      line-height: 0.99872; } }
  @media (min-width: 56.25em) {
    h1, .h1 {
      margin-bottom: 0.08829em;
      font-size: 3.99758em;
      line-height: 0.88289; } }

h2, .h2 {
  margin-bottom: 0.2496em;
  font-size: 1.414em;
  line-height: 1.24802; }
  @media (min-width: 43.75em) {
    h2, .h2 {
      margin-bottom: 0.17652em;
      font-size: 1.9994em;
      line-height: 1.05914; } }
  @media (min-width: 56.25em) {
    h2, .h2 {
      margin-bottom: 0.12484em;
      font-size: 2.82715em;
      line-height: 0.99872; } }

h3, .h3 {
  margin-bottom: 0.2496em;
  font-size: 1.414em;
  line-height: 1.24802; }
  @media (min-width: 56.25em) {
    h3, .h3 {
      margin-bottom: 0.17652em;
      font-size: 1.9994em;
      line-height: 1.05914; } }

h4, .h4 {
  margin-bottom: 0.2496em;
  font-size: 1.414em;
  line-height: 1.24802; }

h5, .h5,
h6, .h6 {
  margin-bottom: 0.35294em;
  font-size: 1em;
  line-height: 1.41176; }

p {
  margin-top: 0;
  margin-bottom: 1.76471em;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 33.88235em; }

small,
.font-small {
  font-size: 0.70721em;
  line-height: 1.49718; }

em {
  font-style: italic; }

strong {
  font-weight: bold; }

blockquote {
  margin-top: 1.41176em;
  margin-bottom: 1.41176em;
  margin-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.41176em;
  border-left: 6px solid gray;
  font-family: Georgia, "Times New Roman", serif; }
  blockquote p {
    margin-bottom: 0.70588em; }
  blockquote footer cite:before {
    content: " – "; }

pre,
code {
  font-family: "Lucida Console", Monaco, monospace; }

time,
.time {
  text-transform: uppercase; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1.41176em;
  margin-left: 1.41176em;
  padding-top: 0;
  padding-bottom: 0; }

ul ul,
ol ol,
dl dl {
  margin-left: 0; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

abbr {
  font-variant: small-caps;
  font-weight: 600;
  text-transform: lowercase; }

abbr[title]:hover {
  cursor: help; }

hr {
  margin-top: 1.41176em;
  margin-bottom: 0;
  border: none;
  border-top-color: #333;
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 1.35294em; }

@font-face {
  font-family: 'logo';
  src: url("/static/fonts/logo.eot?29264817");
  src: url("/static/fonts/logo.eot?29264817#iefix") format("embedded-opentype"), url("/static/fonts/logo.woff?29264817") format("woff"), url("/static/fonts/logo.ttf?29264817") format("truetype"), url("/static/fonts/logo.svg?29264817#logo") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'logo';
    src: url('../font/logo.svg?29264817#logo') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "logo";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-logo:before {
  content: '\e800'; }

/* '' */
.gist {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.99624em;
  font-size: 0.70721em;
  line-height: 1.49718; }

.gist .line-numbers {
  padding: 0.49906em !important; }

.gist-file {
  margin-bottom: 0.49906em !important; }

.gist .gist-file .gist-data,
.gist-file {
  border-top: 1px #eeeeee solid !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px #eeeeee solid !important; }

.gist .gist-file .gist-meta {
  display: none !important; }

.header-container,
.footer-container {
  background-color: #fff;
  color: #333;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center; }
  .header-container a,
  .footer-container a {
    color: #333; }
    .header-container a:link, .header-container a:active,
    .footer-container a:link,
    .footer-container a:active {
      text-decoration: none; }
    .header-container a:hover, .header-container a:visited,
    .footer-container a:hover,
    .footer-container a:visited {
      text-decoration: underline; }

.header-container {
  padding-top: 1.76471em;
  padding-bottom: 1.76471em;
  margin-bottom: 1.41176em;
  font-weight: bold; }
  .header-container .button {
    margin: 0.35294em; }
  .header-container .lead {
    max-width: none;
    text-align: center;
    margin-bottom: 1.76471em; }
  @media (min-width: 43.75em) {
    .header-container {
      padding-top: 4.58824em;
      padding-bottom: 4.58824em;
      margin-bottom: 1.41176em; } }
  @media (min-width: 56.25em) {
    .header-container {
      padding-top: 9.17647em;
      padding-bottom: 9.17647em;
      margin-bottom: 2.82353em; } }

.footer-container {
  padding: 2.82353em 1.05882em; }
  .footer-container p {
    max-width: none;
    text-align: center;
    margin-bottom: 0.99812em;
    font-size: 0.70721em;
    line-height: 1.49718; }

a {
  color: #333; }
  a:link, a:active {
    text-decoration: none; }
  a:hover, a:visited {
    text-decoration: underline; }

.logo {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.icon-logo {
  font-size: 1.9994em;
  line-height: 1.05914; }
  @media (min-width: 43.75em) {
    .icon-logo {
      font-size: 2.82715em;
      line-height: 0.99872; } }
  @media (min-width: 56.25em) {
    .icon-logo {
      font-size: 5.65258em;
      line-height: 0.62439; } }

.button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  user-select: none;
  border-style: solid;
  border-color: #333;
  border-width: 2px;
  padding: 0.94118em; }

a.button:hover {
  text-decoration: none; }

.button-default {
  border-color: #333;
  color: #333; }
  .button-default:hover, .button-default:active {
    color: #fff; }
  .button-default:hover {
    background-color: #333; }
  .button-default:active {
    background-color: #e6e6e6; }

.button-primary {
  border-color: #333;
  color: #333; }
  .button-primary:hover, .button-primary:active {
    color: #fff; }
  .button-primary:hover {
    background-color: #333; }
  .button-primary:active {
    background-color: #333333; }

.main-container {
  padding: 0 1.05882em;
  max-width: 38.82353em;
  margin-left: auto;
  margin-right: auto; }

article section:after, article header:after {
  content: ' ';
  display: block;
  border-color: #cccccc;
  border-bottom-style: solid;
  margin-bottom: 2.82353em;
  height: 0;
  border-bottom-width: 1px;
  padding-bottom: 1.35294em; }

article {
  border-top-color: #333;
  border-top-style: solid;
  border-top-width: 12px;
  padding-top: 0.70588em; }
  article p {
    max-width: none; }

code {
  font-size: 0.70721em;
  background-color: #e6e6e6;
  padding: 0.49906em;
  border-radius: 4px;
  word-wrap: break-word; }

.to-top {
  text-align: center; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.social-buttons {
  list-style: none;
  margin-bottom: 0.70588em;
  margin-left: 0;
  height: 1.41176em; }
  .social-buttons li {
    display: inline-block;
    height: 1.41176em; }

.fa {
  margin-left: 0.17647em; }

.main-heading {
  margin-bottom: 0.99842em;
  font-size: 1.414em;
  line-height: 1.24802; }
  @media (min-width: 43.75em) {
    .main-heading {
      margin-bottom: 0.7061em;
      font-size: 1.9994em;
      line-height: 1.05914; } }
  @media (min-width: 56.25em) {
    .main-heading {
      margin-bottom: 0.49936em;
      font-size: 2.82715em;
      line-height: 0.99872; } }
