@charset "UTF-8";

@font-face {
  font-family: 'logo';
  src: url('/static/fonts/logo.eot?29264817');
  src: url('/static/fonts/logo.eot?29264817#iefix') format('embedded-opentype'),
  url('/static/fonts/logo.woff?29264817') format('woff'),
  url('/static/fonts/logo.ttf?29264817') format('truetype'),
  url('/static/fonts/logo.svg?29264817#logo') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'logo';
    src: url('../font/logo.svg?29264817#logo') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "logo";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-logo:before { content: '\e800'; } /* '' */
